import React from 'react';

// Styles
import {
    CopyrightContainer,
    Text,
    CopyrightUrls,
    UrlItem,
} from './copyright-styled';

const Copyright = ({ direction, backgroundColor, textColor }) => {
    return (
        <CopyrightContainer
            direction={direction}
            backgroundColor={backgroundColor}
        >
            <Text color={textColor}>
                © 2001-{new Date().getFullYear()}. J. Câmara e Irmãos S/A. CNPJ
                01.536.754/0001-23 - Rua Thomas Edson, 400, Serrinha - Goiânia -
                GO - 74853-130
            </Text>

            <CopyrightUrls>
                <UrlItem
                    color={textColor}
                    href={process.env.NEXT_PUBLIC_URL_FAQ}
                >
                    FAQ
                </UrlItem>
                <UrlItem
                    color={textColor}
                    href={process.env.NEXT_PUBLIC_URL_FALECONOSCO}
                >
                    FALE CONOSCO
                </UrlItem>
                <UrlItem
                    color={textColor}
                    href={process.env.NEXT_PUBLIC_URL_TERMOS_DE_USO}
                >
                    TERMOS DE USO
                </UrlItem>
                <UrlItem
                    color={textColor}
                    href={process.env.NEXT_PUBLIC_URL_POLITICA_DE_PRIVACIDADE}
                >
                    POLÍTICA DE PRIVACIDADE
                </UrlItem>
            </CopyrightUrls>
        </CopyrightContainer>
    );
};

Copyright.defaultProps = {
    direction: 'row',
    backgroundColor: ' #2b043f',
    textColor: '#FFF',
};

export default Copyright;
