import React, { useState } from 'react';
import { useKeycloak } from '@react-keycloak/nextjs';
import Link from 'next/link';
import Image from 'next/image';

import {
    TopBarContainer,
    TopBarContent,
    LogoContainer,
    Logo,
    AnchorContainer,
    HiddenControl,
    TopBarWrapper,
    Wallpaper,
} from './topbar-styled';
import { TextButton, Titulo } from '../../styles/theme-styled';

import Hamburguer from './hamburguer';
import Login from './login';
import SelectVitrine from './select-vitrine';

const Topbar = ({
    backgroundColor,
    menuListAuthenticate,
    LogoClassi,
    classiType = '',
}) => {
    const { keycloak } = useKeycloak();
    const parsedToken = keycloak?.tokenParsed;

    const [openWrapper, setOpenWrapper] = useState(false);
    const [openOverlay, setOpenOverlay] = useState(false);

    const handleLogoutLink = () => {
        if (keycloak)
            window.location.href = keycloak.createLogoutUrl({
                redirectUri: window.location.origin,
            });
    };

    const handleLoginLink = () => {
        if (keycloak) window.location.href = keycloak.createLoginUrl();
    };

    const toggleWrapper = () => {
        if (openOverlay) closeWrapperFilterOverlay();
        else {
            setOpenWrapper((prevState) => !prevState);
            setOpenOverlay(true);
        }
    };

    const closeWrapperFilterOverlay = () => {
        setOpenWrapper(false);
        setOpenOverlay(false);
    };

    const anchorContainer = () => {
        return (
            <AnchorContainer backgroundColor={backgroundColor}>
                {!keycloak?.authenticated &&
                    menuListAuthenticate.map((item) => (
                        <TextButton
                            key={item.label}
                            ariel-label={item.label}
                            href={item.href}
                            onClick={item.onClick}
                            btncolor={item.btnColor}
                        >
                            {item.label}
                        </TextButton>
                    ))}

                {!keycloak?.authenticated && (
                    <TextButton
                        key={'Entrar'}
                        ariel-label={'Entrar'}
                        onClick={handleLoginLink}
                        btncolor={'#FFF'}
                    >
                        Entrar
                    </TextButton>
                )}

                {keycloak?.authenticated &&
                    menuListAuthenticate.map((item) => (
                        <TextButton
                            key={item.label}
                            ariel-label={item.label}
                            href={item.href}
                            onClick={item.onClick}
                            btncolor={item.btnColor}
                        >
                            {item.label}
                        </TextButton>
                    ))}

                {keycloak?.authenticated && (
                    <Login
                        backgroundColor={backgroundColor}
                        parsedToken={parsedToken}
                        onClickLogout={handleLogoutLink}
                    />
                )}
            </AnchorContainer>
        );
    };

    return (
        <TopBarContainer {...{ backgroundColor }}>
            <TopBarContent>
                <Hamburguer
                    onClick={toggleWrapper}
                    open={openWrapper}
                ></Hamburguer>

                <LogoContainer href="/">
                    <Logo
                        src={LogoClassi}
                        alt={`Logo Classi ${classiType.toUpperCase()}`}
                    />
                </LogoContainer>

                <HiddenControl desktop={false} mobile={true}>
                    {anchorContainer()}
                </HiddenControl>
            </TopBarContent>

            <TopBarWrapper
                bgimage={TopBarWrapper}
                isopen={openWrapper}
                ß
                {...{ parsedToken }}
            >
                <HiddenControl desktop={true} mobile={false}>
                    <AnchorContainer backgroundColor={backgroundColor}>
                        {keycloak?.authenticated && (
                            <TextButton
                                ariel-label={`Olá ${parsedToken?.given_name}`}
                                btncolor="#fff"
                            >
                                <Link href="/perfil">
                                    <a>{`Olá ${parsedToken?.given_name}`}</a>
                                </Link>
                            </TextButton>
                        )}

                        {anchorContainer()}
                    </AnchorContainer>
                </HiddenControl>

                <Wallpaper>
                    <Image
                        alt="imoveis"
                        src="/imgs/capa-poly-bg.jpg"
                        layout="fill"
                        objectFit="cover"
                        objectPosition="left top"
                    />
                </Wallpaper>
                <Titulo>O que você procura?</Titulo>
                <SelectVitrine />
            </TopBarWrapper>
        </TopBarContainer>
    );
};

export default Topbar;
