import React from 'react';

import { CardContainer, Card } from './select-vitrine-styled';

const handleNavigateToVitrine = (url) => {
    window.location.href = url;
};

const SelectVitrine = () => {
    return (
        <CardContainer>
            <Card
                color="#00bcd4"
                onClick={() =>
                    handleNavigateToVitrine(
                        process.env.NEXT_PUBLIC_URL_VITRINE_IMOVEIS
                    )
                }
            >
                Imóveis
            </Card>

            <Card
                color="#cf2429"
                onClick={() =>
                    handleNavigateToVitrine(
                        process.env.NEXT_PUBLIC_URL_VITRINE_CARROS
                    )
                }
            >
                Carros
            </Card>

            <Card
                color="#940057"
                onClick={() =>
                    handleNavigateToVitrine(
                        process.env.NEXT_PUBLIC_URL_VITRINE_MOTOS
                    )
                }
            >
                Motos
            </Card>

            <Card
                color="#0b9140"
                onClick={() =>
                    handleNavigateToVitrine(
                        process.env.NEXT_PUBLIC_URL_VITRINE_AGRO
                    )
                }
            >
                Agro
            </Card>

            <Card
                color="#ffa000"
                onClick={() =>
                    handleNavigateToVitrine(
                        process.env.NEXT_PUBLIC_URL_VITRINE_NEGOCIOS
                    )
                }
            >
                Negócios
            </Card>

            <Card
                color="#f07226"
                onClick={() =>
                    handleNavigateToVitrine(
                        process.env.NEXT_PUBLIC_URL_VITRINE_EMPREGOS
                    )
                }
            >
                Empregos
            </Card>
        </CardContainer>
    );
};

export default SelectVitrine;
