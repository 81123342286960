import React from 'react';

import {
    HamburguerContainer,
    StripeTop,
    StripeMiddle,
    StripeBottom,
} from './hamburguer-styled';

const Hamburguer = ({ onClick, open }) => {
    return (
        <HamburguerContainer {...{ onClick }}>
            <StripeTop {...{ open }}></StripeTop>
            <StripeMiddle {...{ open }}></StripeMiddle>
            <StripeBottom {...{ open }}></StripeBottom>
        </HamburguerContainer>
    );
};

export default Hamburguer;
