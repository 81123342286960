import styled from 'styled-components';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { darken } from '@material-ui/core/styles/colorManipulator';

export const Dropdown = styled.div`
    list-style-type: none;
    display: flex;
    margin-left: 25px;
    padding: 0;

    @media screen and (max-width: 768px) {
        display: none;
    }

    .dropdown-arrow {
        margin-top: 7px;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: ${(props) => props.backgroundColor};
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        right: 0;
        top: 40px;
    }

    .dropdown-content button {
        width: 100%;
        color: #fff;
        padding: 12px 16px;
        display: block;
        text-align: left;
        font-weight: normal;
        border-bottom: 0.5px solid #fff;

        &:first-of-type {
            font-weight: 600;
        }
        &:last-of-type {
            background-color: ${(props) => darken(props.backgroundColor, 0.3)};
        }
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }
`;

export const UserIcon = styled(AccountCircle)`
    font-size: 35px;

    color: #3d1a54;
`;
