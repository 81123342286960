import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    max-width: 700px;

    padding: 30px;
`;

export const Card = styled(({ ...other }) => <Button {...other} />)`
    && {
        width: 90px;
        height: 95px;
        border-radius: 10px;
        padding: 20px 10px 50px;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        position: relative;
        top: 0;

        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;

        background-color: ${(props) => props.color};
        color: #fff;

        &:hover {
            cursor: pointer;
            position: relative;
            top: -5px;
            transition: top 0.3s ease;

            background-color: ${(props) => props.color};

            &:after {
                content: '';
                position: absolute;
                bottom: -10px;
                left: 50%;
                right: 50%;
                margin-left: -10px;
                margin-right: -10px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #ffa000;
            }
        }
    }
`;
