import styled from 'styled-components';

export const PopUpContainer = styled.section`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.85);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: ${props => props.zIndex ?  props.zIndex : 99};
    
    .close-button {
        position: absolute;
        right: 0px;
        top: -50px;
        font-size: 40px;
        font-weight: bold;
        color: #757575;
        cursor: pointer;
    }
`;

export const Img = styled.img`
    width: 30px;
    position: absolute;
    top: 3%;
    right: 3%;
    cursor: pointer;
`;

export const Box = styled.div`
    max-width: 960px;
    width: 95%;
    max-height: 80%;
    overflow-y: auto;
    background-color: #ffffff;
    position: relative;
    padding: 50px;

    @media screen and (max-width: 700px) {
        padding: 30px 10px;
    }
`;
