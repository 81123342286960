import React, { useEffect } from 'react';

//Components
import Hidden from '@material-ui/core/Hidden';

//Styles
import {
    FooterContainer,
    Logo,
    LogoContainer,
    SocialContainer,
    SocialButton,
    UrlList,
    InformacaoContainer,
    Titulo,
    SimpleText,
    Icon,
    Phone,
} from './footer-styled';

const Footer = ({ tituloColor, phoneColor, backgroundColor, textColor }) => {
    // Instana Script Injection
    useEffect(() => {
        // Inject inline script
        const inlineScript = document.createElement('script');
        inlineScript.type = 'text/javascript';
        inlineScript.innerHTML = `
      (function(s,t,a,n){s[t]||(s[t]=a,n=s[a]=function(){n.q.push(arguments)},
      n.q=[],n.v=2,n.l=1*new Date)})(window,"InstanaEumObject","ineum");

      ineum('reportingUrl', 'https://eum-red-saas.instana.io');
      ineum('key', 'SUf67hb9S-WjCnmVvV22Gw');
      ineum('trackSessions');
    `;
        document.head.appendChild(inlineScript);

        // Inject external script
        const externalScript = document.createElement('script');
        externalScript.defer = true;
        externalScript.crossOrigin = 'anonymous';
        externalScript.src = 'https://eum.instana.io/1.7.2/eum.min.js';
        externalScript.integrity =
            'sha384-cgeSlevgebehPauohUhsnAeBrpjXzaj94mSv3L2EXjCQH0RRb9xSQ2ErGOWkthIJ';
        document.head.appendChild(externalScript);

        return () => {
            // Cleanup scripts when component unmounts
            document.head.removeChild(inlineScript);
            document.head.removeChild(externalScript);
        };
    }, []);

    return (
        <FooterContainer backgroundColor={backgroundColor}>
            <LogoContainer>
                <a
                    href={process.env.NEXT_PUBLIC_URL_VITRINE_CAPA}
                    aria-label="Classi Empregos logo"
                >
                    <Logo
                        src="/svg/logo-classi.svg"
                        alt="Classi Empregos logo"
                    />
                </a>

                <SocialContainer>
                    <SocialButton variant="outlined">
                        <a
                            href={process.env.NEXT_PUBLIC_URL_FACEBOOK}
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Facebook"
                        >
                            <Icon
                                src="/svg/facebook-logo.svg"
                                alt="Facebook icon"
                            />
                        </a>
                    </SocialButton>

                    <SocialButton variant="outlined">
                        <a
                            href={process.env.NEXT_PUBLIC_URL_TWITTER}
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Twitter"
                        >
                            <Icon
                                src="/svg/twitter-logo.svg"
                                alt="Twitter icon"
                            />
                        </a>
                    </SocialButton>
                </SocialContainer>
            </LogoContainer>

            <Hidden xsDown>
                <UrlList>
                    <Titulo color={textColor}>Classificados</Titulo>
                    <UrlList.Item
                        color={textColor}
                        as="a"
                        href={process.env.NEXT_PUBLIC_URL_ANUNCIE}
                    >
                        Anuncie
                    </UrlList.Item>
                    <UrlList.Item
                        color={textColor}
                        as="a"
                        href={process.env.NEXT_PUBLIC_URL_VITRINE_AGRO}
                    >
                        Agro
                    </UrlList.Item>
                    <UrlList.Item
                        color={textColor}
                        as="a"
                        href={process.env.NEXT_PUBLIC_URL_VITRINE_CARROS}
                    >
                        Carros
                    </UrlList.Item>
                    <UrlList.Item
                        color={textColor}
                        as="a"
                        href={process.env.NEXT_PUBLIC_URL_VITRINE_IMOVEIS}
                    >
                        Imóveis
                    </UrlList.Item>
                    <UrlList.Item
                        color={textColor}
                        as="a"
                        href={process.env.NEXT_PUBLIC_URL_VITRINE_EMPREGOS}
                    >
                        Empregos
                    </UrlList.Item>
                    <UrlList.Item
                        color={textColor}
                        as="a"
                        href={process.env.NEXT_PUBLIC_URL_VITRINE_MOTOS}
                    >
                        Motos
                    </UrlList.Item>
                    <UrlList.Item
                        color={textColor}
                        as="a"
                        href={process.env.NEXT_PUBLIC_URL_VITRINE_NEGOCIOS}
                    >
                        {' '}
                        Negócios
                    </UrlList.Item>
                    <UrlList.Item
                        color={textColor}
                        as="a"
                        href={process.env.NEXT_PUBLIC_URL_MIDIA_KIT_ONLINE}
                    >
                        Mídia Kit Online
                    </UrlList.Item>
                </UrlList>

                <UrlList>
                    <Titulo color={textColor}>Grupo Jaime Câmara</Titulo>

                    <UrlList.Item
                        color={textColor}
                        as="a"
                        href={process.env.NEXT_PUBLIC_URL_OPOPULAR}
                    >
                        O Popular
                    </UrlList.Item>
                    <UrlList.Item
                        color={textColor}
                        as="a"
                        href={process.env.NEXT_PUBLIC_URL_DAQUI}
                    >
                        Jornal Daqui
                    </UrlList.Item>
                    <UrlList.Item
                        color={textColor}
                        as="a"
                        href={process.env.NEXT_PUBLIC_URL_LUDOVICA}
                    >
                        Ludovica
                    </UrlList.Item>
                    <UrlList.Item
                        color={textColor}
                        as="a"
                        href={process.env.NEXT_PUBLIC_URL_CLUBE_OPOPULAR}
                    >
                        Clube O Popular
                    </UrlList.Item>
                    <UrlList.Item
                        color={textColor}
                        as="a"
                        href={process.env.NEXT_PUBLIC_URL_CBN}
                    >
                        CBN
                    </UrlList.Item>
                </UrlList>

                <InformacaoContainer>
                    <Titulo color={tituloColor}>
                        Fale Conosco
                        <br />
                    </Titulo>
                    <Phone color={phoneColor}>62 3250.5323</Phone>

                    <SimpleText color={textColor}>
                        Todas as regras e promoções são válidas apenas para
                        produtos vendidos e entregues pelo classi.com.br. O
                        preço válido será o da finalização da compra. J. Câmara
                        e Imãos S/A / CNPJ: 01.536.754/0001-23 / Endereço: Rua
                        Thomas Edison, nº 400, Qd 07 - Setor Serrinha - Goiânia,
                        GO - CEP: 74.835-130 / Atendimento ao cliente:
                        atendimento@classi.com.br
                    </SimpleText>
                </InformacaoContainer>
            </Hidden>
        </FooterContainer>
    );
};

Footer.defaultProps = {
    tituloColor: '#e7891a',
    phoneColor: '#e7891a',
    backgroundColor: ' #2b043f',
    textColor: '#FFF',
};

export default Footer;
