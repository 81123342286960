import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

import { AvatarHeader, DropDownArrow } from 'utils/icons';
import { Dropdown } from './login-styled';
import { TextButton } from 'styles/theme-styled';

const Login = ({ backgroundColor, parsedToken, onClickLogout }) => {
    return (
        <Dropdown backgroundColor={backgroundColor}>
            <TextButton>
                <Image
                    alt="Avatar Header"
                    src={AvatarHeader}
                    width={25}
                    height={25}
                />
            </TextButton>
            <div className="dropdown">
                <div className="dropdown-arrow">
                    <TextButton>
                        <Image
                            alt="Abrir menu"
                            src={DropDownArrow}
                            width={13}
                            height={13}
                        />
                    </TextButton>
                </div>

                <div className="dropdown-content">
                    <TextButton
                        ariel-label={`Olá ${parsedToken?.given_name}`}
                        btncolor="#fff"
                    >
                        <Link href="/perfil">
                            <a>{`Olá ${parsedToken?.given_name}`}</a>
                        </Link>
                    </TextButton>
                    <TextButton
                        onClick={onClickLogout}
                        className="last"
                        ariel-label="Sair"
                        btncolor="#fff"
                    >
                        Sair
                    </TextButton>
                </div>
            </div>
        </Dropdown>
    );
};

export default Login;
