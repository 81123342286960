import styled from 'styled-components';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Link from 'next/link';

export const TopBarContainer = styled.header`
    width: 100%;
    min-height: 50px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: ${(props) => props.backgroundColor};
    border-bottom: ${(props) =>
        props.backgroundColor != '#FFF'
            ? '6px solid ' + darken(props.backgroundColor, 0.3)
            : 'null'};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
`;

export const TopBarContent = styled.div`
    width: 100%;
    max-width: 1280px;
    padding-left: 10px;
    padding-right: 10px;
    height: 50px;

    display: flex;
    flex-direction: row;
    align-items: center;

    position: relative;
    z-index: 99;
`;

export const LogoContainer = styled(Link)`
    height: 50px;

    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        position: absolute;
        top: 0;
        left: 50%;
        right: 50%;
        margin-left: -52px;
        margin-right: -52px;
    }
`;

export const Logo = styled.img`
    width: 103px;
    height: 30px;
    cursor: pointer;
    margin: auto;

    @media screen and (max-width: 768px) {
        position: relative;
        left: -30px;
    }
`;

export const AnchorContainer = styled.nav`
    width: auto;
    min-width: 300px;
    display: flex;
    margin-left: auto;
    margin-right: 0;

    button {
        margin-right: 16px;

        &:last-of-type {
            margin-right: 0;
        }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: ${(props) => props.backgroundColor};

        button {
            padding: 20px 0;
            border-bottom: 1px solid #fff;
            margin-right: 0;
        }

        .last {
            background-color: ${(props) => darken(props.backgroundColor, 0.3)};
        }
    }
`;

export const HiddenControl = styled.div`
    display: ${(props) =>
        props.desktop ? 'none' : props.display ? props.display : 'flex'};

    width: 100%;
    color: #fff;

    @media screen and (max-width: 768px) {
        display: ${(props) =>
        props.mobile ? 'none' : props.display ? props.display : 'flex'};
    }
`;

export const TopBarWrapper = styled.div`
    width: 100%;
    height: auto;

    max-height: ${(props) => (props.isopen ? '750px' : '0')};

    overflow: auto;
    ::-webkit-scrollbar {
        display: none;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    opacity: ${(props) => (props.isopen ? '1' : '0')};
    transition: max-height 0.5s ease;

    @media screen and (max-width: 768px) {
        display: block;
        position: fixed;
        top: 56px;
        bottom: 0;
        left: 0;
        right: 0;
    }
`;

export const Wallpaper = styled.div`
    position: absolute;
    overflow: hidden;
    z-index: -1;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 768px) {
        height: 414px;
        margin: 0px;
    }
`;
