import React from 'react';
import Image from 'next/image';
import { PopUpContainer } from '../Popup/popup-styled';
import { DialogContent } from './desempenho-styled';

const Desempenho = () => {
    const isIE =
        typeof window !== 'undefined'
            ? navigator.appVersion.indexOf('Trident/') > 0
            : false;

    return (
        <>
            {isIE && (
                <PopUpContainer zIndex={100}>
                    <DialogContent>
                        <h2>
                            {'Quer uma melhor experiência ao acessar CLASSI'}
                        </h2>
                        <h4>
                            Nosso portal funciona melhor nos
                            <br />
                            navegadores: Chrome, Firefox e Safari!
                            <br />
                            Mude seu navegador.
                        </h4>

                        <div>
                            <a
                                href="https://www.google.com/chrome/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Image
                                    src="/svg/chrome_icon.svg"
                                    alt="Download Chrome"
                                />
                            </a>
                            <a
                                href="https://www.mozilla.org/en-US/firefox/new/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Image
                                    src="/svg/firefox_icon.svg"
                                    alt="Download Firefox"
                                />
                            </a>
                            <a
                                href="https://support.apple.com/downloads/safari"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Image
                                    src="/svg/safari_icon.svg"
                                    alt="Download Safari"
                                />
                            </a>
                        </div>
                    </DialogContent>
                </PopUpContainer>
            )}
        </>
    );
};

export default Desempenho;
