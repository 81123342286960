import styled from 'styled-components';

export const DialogContent = styled.div`
width: 564px;
height: 395px;

display: flex;
/* align-items: center; */
flex-direction: column;
justify-content: center;
background-color: #FFF;

padding: 50px 70px 70px 46px;
box-sizing: border-box;
@media screen and ( max-width: 736px ) {
  width: 375px;
  height: 267px;
  padding: 24px 32px 32px 24px;
}

h2{
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  color: #1d769e;
  margin: 0 0 32px;
  @media screen and ( max-width: 736px ) {
    font-size: 16px;
  }
}
h4{
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  color: #4a4a4a;
  margin: 0 0 32px;
  @media screen and ( max-width: 736px ) {
    font-size: 14px;
  }
}
img{
  width: 100px;
  height: 100px;
  @media screen and ( max-width: 736px ) {
    width: 50px;
    height: 50px;
  }
}
> div{
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  > a{
    margin-right: 32px;
    &:last-of-type{
      margin-right: 0;
    }
  }
}
`;
