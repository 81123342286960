import React from 'react';
import Head from 'next/head';
import { useKeycloak } from '@react-keycloak/nextjs';
import styled from 'styled-components';

import Desempenho from 'components/Desempenho';
import Topbar from 'components/TopBar';
import Footer from 'components/Footer';
import Copyright from 'components/Copyright';
import LogoClassi from '/public/svg/logo-classi-imoveis.svg';
import { theme } from '../../utils/vitrine.colors';

const vitrineType = 'imoveis';

const Wrapper = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    flex: 1;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Layout = ({ children }) => {
    const { keycloak } = useKeycloak();

    const handleRegisterLink = () => {
        if (keycloak) window.location.href = keycloak.createRegisterUrl();
    };

    const handleRedirect = (url) => {
        if (keycloak?.authenticated) return (window.location.href = url);
        window.location.href = keycloak.createLoginUrl();
    };

    const menuListAuthenticate = [
        {
            btnColor: '#FFF',
            label: 'Favoritos',
            onClick: () => handleRedirect('/favoritos'),
        },
        {
            btnColor: '#FFF',
            label: 'Acesse seu plano',
            onClick: () => handleRedirect(process.env.NEXT_PUBLIC_URL_PLANOS),
        },
        {
            btnColor: '#FFF',
            label: 'Anuncie',
            onClick: () => handleRedirect(process.env.NEXT_PUBLIC_URL_ANUNCIE),
        },
    ];

    const menuListNotAuthenticate = [
        {
            btnColor: '#FFF',
            label: 'Acesse seu plano',
            onClick: () => handleRedirect(process.env.NEXT_PUBLIC_URL_PLANOS),
        },
        {
            btnColor: '#FFF',
            label: 'Cadastre-se',
            onClick: () => handleRegisterLink(),
        },
        {
            btnColor: '#FFF',
            label: 'Anuncie',
            onClick: () => handleRedirect(process.env.NEXT_PUBLIC_URL_ANUNCIE),
        },
    ];

    return (
        <Wrapper>
            <Desempenho />

            <Topbar
                LogoClassi={LogoClassi}
                classiType={vitrineType}
                backgroundColor={theme[vitrineType].main}
                menuListAuthenticate={menuListAuthenticate}
                menuListNotAuthenticate={menuListNotAuthenticate}
            />

            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
            </Head>

            <Container>{children}</Container>

            <Footer />
            <Copyright />
        </Wrapper>
    );
};

export default Layout;
