import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const TextButton = styled(Button)`
    &&,
    a {
        font-size: 14px;
        color: ${(props) => (props.btncolor ? props.btncolor : '#9b43c4')};
        font-weight: 600;
    }
`;

export const Titulo = styled.p`
    font-size: 36px;
    color: #9b43c4;
    text-align: center;
    text-transform: uppercase;

    font-weight: bold;

    margin-top: 40px;
    margin-bottom: 20px;
`;

export const temaRoxo = {
    primaryColor: '#2b063f',
    primaryColorLight: '#2b063f',
    primaryColorDark: '#1b0228',
};

export const temaLaranja = {
    primaryColor: '#f07227',
    primaryColorLight: '#f07226',
    primaryColorDark: '#f07227',
};
